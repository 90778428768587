import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 7`}</h1>
    <p>{`Sending out love with another week of fresh music. I’ve stumbled across a release by a completely unknown artist called Horizontal Phase, it’s definitely my favorite music of this week. Other stuff includes a debut solo release from Eris Drew, Rrose & Silent Servant combined forces on a diverse compilation and RX-101 brings you back into vintage-Rephlex sounds from 1995.`}<span style={{
        display: 'block',
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="" seamless>
  <a href="http://conradpack.bandcamp.com/album/horizontal-phase">
    Horizontal Phase by Horizontal Phase
  </a>
    </iframe>
    <h1>{`Horizontal Phase - Horizontal Phase`}</h1>
    <p>{`I couldn’t find anything about this artist called Horizontal Phase. The music on the same-named EP sounds absolutely amazing though. It covers aspects of genres like electro, ambient en jungle.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://conradpack.bandcamp.com/album/horizontal-phase"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2387994345/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://erisdrew.bandcamp.com/album/fluids-of-emotion-it-44">
    Fluids of Emotion [IT 44] by Eris Drew
  </a>
    </iframe>
    <h1>{`Eris Drew - Fluids of Emotion`}</h1>
    <p>{`The debut solo release from Eris Drew. It covers 3 new songs mixed for the maximum impact of the Motherbeat.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://erisdrew.bandcamp.com/album/fluids-of-emotion-it-44"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=3578470687/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://airtexture.bandcamp.com/album/air-texture-vii">
    Air Texture VII by Rrose + Silent Servant
  </a>
    </iframe>
    <h1>{`Rrose & Silent Servant - Air Texture VII`}</h1>
    <p>{`Rrose and Silent Servant collaborated on a combined release for The Air Texture series. This series presents a diverse array of music on the edges of experimental and dance.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://airtexture.bandcamp.com/album/air-texture-vii"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=1989562496/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://rx-101.bandcamp.com/album/serenity-2">Serenity by RX-101</a>
    </iframe>
    <h1>{`RX-101 - Serenity`}</h1>
    <p>{`RX-101 has quietly been making IDM/electronic music for decades. Serenity sounds like a secret alias of Aphex Twin with his well-known sounds from the early-90s on Rephlex.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://rx-101.bandcamp.com/album/serenity-2"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      